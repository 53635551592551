import React, { FC, useContext } from "react";
import * as styles from "./SaasApplicationMain.module.scss";
import { UseWindowSize } from "../hooks/UseWindowSize";
import translate from "../../i18n/translate";
import HeroBanner from "../Shared/HeroBanner/HeroBanner";
import HeroBannerMobile from "../Shared/HeroBannerMobile/HeroBannerMobile";
import InnerWrapper from "../Shared/InnerWrapper/InnerWrapper";
import TwoColumnBanner from "../Shared/TwoColumnImgTextbanner/TwoColumnBanner";
import {
  TwoColumnBannerData,
  BenefitData,
  ourApproachData,
} from "./SaasApplication.helper";
import KeyTopicsComponent from "../Shared/KeyTopicsComponent/KeytopicsComponent";
import BenefitsComponent from "../Shared/BenefitsComponent/BenefitsComponent";
import OurApproachComponent from "../Shared/OurApproachComponent/OurApproachComponent";
import CircleOutline from "../Shared/CircleOutlineAndBlur/CircleOutline";
import CustomButton from "../Shared/CustomButton/CustomButton";
import arrowRightWhite from "../../images/arrowRightWhite.svg";
import CircleBlur from "../Shared/CircleOutlineAndBlur/CircleBlur";
import SectionWrapper from "../Shared/SectionWrapper/SectionWrapper";
import CaseStudiesMain from "../HomeUiComponents/CaseStudiesMain/CaseStudiesMain";
import ContactForm from "../Shared/ContactUiComponents/ContactForm";
import sassReview from "../../images/saasReview.webp";
import HomeCaseStudiesMobile from "../HomeUiComponents/HomeMobileComponents/HomeCaseStudiesMobile/HomeCaseStudiesMobile";
import sassStage from "../../images/sassStage.jpg";
import { PopupButton } from "react-calendly";
import { LanguageContext } from "../../context/languageContext";

type Props = {
  data: any;
};

const SaasApplicationMain: FC<Props> = ({ data }) => {
  const width = UseWindowSize();
  const { language } = useContext(LanguageContext);

  return (
    <div className={styles.sassApplication}>
      {width.width < 1200 ? (
        <HeroBannerMobile
          heading={translate("saas-solution-header")}
          description={translate("saas-solution-subtitel")}
          homeBackground={sassStage}
          buttonText={translate("home-contactus")}
          date=""
          link="/contact"
          isExternalLink
          alt="Become a creator"
        />
      ) : (
        <HeroBanner
          heading={translate("saas-solution-header")}
          description={translate("saas-solution-subtitel")}
          homeBackground={sassStage}
          buttonText={translate("home-contactus")}
          date=""
          link="/contact"
          isExternalLink
        />
      )}
      <InnerWrapper>
        <div className={styles.banner}>
          <TwoColumnBanner data={TwoColumnBannerData} />
        </div>
        <div className={styles.approach}>
          <KeyTopicsComponent
            keyTopicsTitle={translate("saas-solution-details-header")}
            KeytopicsData={[
              {
                count: "01",
                heading: translate("saas-solution-details-01-header"),
                description: translate("saas-solution-details-01-description"),
              },
              {
                count: "02",
                heading: translate("saas-solution-details-02-header"),
                description: translate("saas-solution-details-02-description"),
              },
              {
                count: "03",
                heading: translate("saas-solution-details-03-header"),
                description: translate("saas-solution-details-03-description"),
              },
              {
                count: "04",
                heading: translate("saas-solution-details-04-header"),
                description: translate("saas-solution-details-04-description"),
              },
              {
                count: "05",
                heading: translate("saas-solution-details-05-header"),
                description: translate("saas-solution-details-05-description"),
              },
              {
                count: "06",
                heading: translate("saas-solution-process-06-header"),
                description: translate("saas-solution-process-06-description"),
              },
            ]}
          />
        </div>
        <div className={styles.benefits}>
          <BenefitsComponent
            data={BenefitData}
            lineClass={""}
            isCareer
            buttonText={translate("saas-solution-process-header")}
            linkPath="#contact"
          />
        </div>
        <div className={styles.saasreview}>
          <h2>.{translate("saas-review-header")}</h2>
        </div>
        <div className={styles.knowUs}>
          <div className={styles.knowUsImage}>
            <div className={styles.knowUsSubDiv}>
              <img src={sassReview} alt="" className={styles.awsMainImage} />
            </div>
          </div>
          <div className={styles.knowUsText}>
            <p>{translate("saas-review-description")}</p>
            {width.width < 1250 ? (
              <CustomButton
                text={language === "EN" ? "Book now" : "Jetzt buchen"}
                image={arrowRightWhite}
                externalLink="https://calendly.com/globaldatanet/saas-review"
                buttonClass={styles.contactButton}
              />
            ) : typeof window !== "undefined" ? (
              <PopupButton
                url="https://calendly.com/globaldatanet/saas-review"
                rootElement={document.getElementById("___gatsby")}
                text={language === "EN" ? "Book now" : "Jetzt buchen"}
                className={styles.contactButton}
                pageSettings={{
                  backgroundColor: "111111",
                  hideEventTypeDetails: false,
                  hideLandingPageDetails: false,
                  primaryColor: "00ecbd",
                  textColor: "ffffff",
                }}
              />
            ) : (
              <CustomButton
                text={translate("saas-review-button")}
                image={arrowRightWhite}
                externalLink="https://calendly.com/globaldatanet/saas-review"
                buttonClass={styles.contactButton}
              />
            )}

            <CircleOutline
              isBlurVisible={true}
              initialScale={0.3}
              animateScale={1}
              transitionType={"easeIn"}
              transitionDelay={0.2}
              transitionDuration={1}
              circleStyleClass={styles.heroBackgroundCircleLeft}
            />
            <CircleBlur
              initialScale={0.1}
              initialOpacity={0}
              animateScale={1.8}
              animateOpacity={0.15}
              transitionType={"easeIn"}
              transitionDelay={0.2}
              transitionDuration={1}
              circleStyleClass={styles.heroBackgroundInnerCircleLeft}
            />
          </div>
        </div>
        <div className={styles.approach}>
          <OurApproachComponent
            ourApproachData={ourApproachData}
            buttonEnabled={false}
          />
        </div>

        {width.width >= 1200 ? (
          <SectionWrapper>
            <CaseStudiesMain data={data.allSanityCaseStudy} />
          </SectionWrapper>
        ) : (
          <HomeCaseStudiesMobile data={data.allSanityCaseStudy} />
        )}

        <div className={styles.contacted} id="contact">
          <ContactForm heading={translate("saas-form-header")} />
        </div>
      </InnerWrapper>
    </div>
  );
};

export default SaasApplicationMain;
