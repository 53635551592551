// extracted by mini-css-extract-plugin
export var awsMainImage = "SaasApplicationMain-module--awsMainImage--giQg3";
export var contactButton = "SaasApplicationMain-module--contactButton--Dw5IG";
export var gdnPromotePic = "SaasApplicationMain-module--gdnPromotePic--2UHmF";
export var heroBackgroundCircleLeft = "SaasApplicationMain-module--heroBackgroundCircleLeft--VjLOu";
export var heroBackgroundInnerCircleLeft = "SaasApplicationMain-module--heroBackgroundInnerCircleLeft--DvOi4";
export var knowUs = "SaasApplicationMain-module--knowUs--J2TWP";
export var knowUsImage = "SaasApplicationMain-module--knowUsImage--NR65U";
export var knowUsText = "SaasApplicationMain-module--knowUsText--EnjIa";
export var saasreview = "SaasApplicationMain-module--saasreview--yVC2P";
export var sassApplication = "SaasApplicationMain-module--sassApplication--aZy5D";