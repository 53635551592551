import React, { FC } from "react";
import SaasApplicationMain from "../components/SaasApplicationUiComponents/SaasApplicationMain";
import Layout from "../components/Layout/Layout";
import { SEO } from "../components/seo";
import { graphql } from "gatsby";
import sassStage from "../images/sassStage.jpg";

type Props = {
  data: any;
};

const SaasApplication: FC<Props> = ({ data }) => {
  return (
    <Layout location="/blog">
      <SEO
        title="SaaS Application Development"
        description="The next generation of SaaS application well-architected and built by globaldatanet"
        type="website"
        image={sassStage}
      />
      <SaasApplicationMain data={data} />
    </Layout>
  );
};

export default SaasApplication;

export const query = graphql`
  query AllSanityCaseStudyQuery3 {
    allSanityCaseStudy(
      filter: { tags: { elemMatch: { name: { in: ["SaaS", "Development"] } } } }
      sort: { order: DESC, fields: _createdAt }
    ) {
      edges {
        node {
          _rawMainImage(resolveReferences: { maxDepth: 20 })
          title
          titleDE
          mainImage {
            asset {
              gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
            }
          }
          altMainImage
          altAboutImage
          altSolutionImage
          altBenefitsImage
          altChallengeImage
          altTestimonialImage
          caseStudyDescription {
            children {
              text
            }
          }
          caseStudyDescriptionDe {
            children {
              text
            }
          }
        }
      }
    }
    allSanityTag {
      nodes {
        title
      }
    }
  }
`;
