import React, { FC, useRef } from "react";

import loadable from "@loadable/component";
import "./HomeCaseStudiesMobile.scss";
import CircleBlur from "../../../Shared/CircleOutlineAndBlur/CircleBlur";
import { UseImageUrl } from "../../../hooks/UseImageUrl";
import { Link } from "gatsby";
import greenArrowRightBlog from "../../../../images/greenArrowRightBlog.svg";
import arrowGreenLeftBlog from "../../../../images/arrowGreenLeftBlog.svg";

const Carousel = loadable(() => import("react-tiny-slider"));

type Props = {
  data: any;
};

const HomeCaseStudiesMobile: FC<Props> = ({ data }) => {
  const carouselCaseStudies = useRef(null);

  const goNextSlide = () => {
    carouselCaseStudies.current.goTo("next");
  };
  const goPreviousSlide = () => {
    carouselCaseStudies.current.goTo("prev");
  };

  return (
    <div className={"caseStudiesMobileWrapper"}>
      <h2>.Case studies</h2>
      <div className={"blogArrowContainer"}>
        <img
          src={arrowGreenLeftBlog}
          onClick={() => goPreviousSlide()}
          alt=""
        />
        <img src={greenArrowRightBlog} onClick={() => goNextSlide()} alt="" />
      </div>
      <Carousel
        swipeAngle={false}
        items={1}
        mouseDrag
        fixedWidth={260}
        center={false}
        loop={true}
        gutter={45}
        ref={carouselCaseStudies}
        controls={false}
        nav={false}
        style={{ overflow: "hidden" }}
      >
        {data.edges.map(({ node }: any, index: number) => {
          UseImageUrl(node.mainImage, node._rawMainImage);
          let title = node.title
            .toString()
            .toLowerCase()
            .replace(/\s+/g, "-")
            .replace(/[^\w-]+/g, "")
            .replace(/--+/g, "-")
            .replace(/^-+/, "")
            .replace(/-+$/, "");
          return (
            <Link to={`/case-study/${title.toLowerCase()}`} key={index}>
              <div className={"tns-item"}>
                <div className={"slide"}>
                  <img
                    src={
                      node.mainImage.asset.gatsbyImageData.images.fallback.src
                    }
                    alt={node?.altMainImage}
                  />
                  <div>
                    <h4 className="heading">
                      <span>.</span>
                      {node.title}
                    </h4>
                    <p>
                      <span>.</span>
                      {node?.caseStudyDescription[0].children[0].text}
                    </p>
                  </div>
                </div>
              </div>
            </Link>
          );
        })}
      </Carousel>
      <div className={"backgroundCircles"}>
        <CircleBlur
          initialScale={0.1}
          initialOpacity={0}
          animateScale={1.5}
          animateOpacity={0.15}
          transitionType={"easeIn"}
          transitionDelay={0.2}
          transitionDuration={1}
          circleStyleClass={"heroBackgroundInnerCircleLeft"}
        />
      </div>
    </div>
  );
};

export default HomeCaseStudiesMobile;
