import sassStage2 from "../../images/sassStage2.webp";
import translate from "../../i18n/translate";

export const CareerMainData = {
  heading1: translate("saas-solution-intro-header"),
  paragraph1: translate("saas-solution-intro-description"),
  paragraph2: translate("saas-solution-intro-description2"),
  heading2: `.Behind the scenes, the drivers behind`,
};

export const TwoColumnBannerData = {
  heading: CareerMainData.heading1,
  details: CareerMainData.paragraph1,
  details2: CareerMainData.paragraph2,
  img: sassStage2,
  alt: "globaldatanet team at the rooftop on the hamburg office ",
};

export const BenefitData = {
  heading: translate("saas-solution-process-header"),
  benefitsDataMobile: [
    {
      count: "01",
      countPara: "",
      heading: translate("saas-solution-process-01-header"),
      description: translate("saas-solution-process-01-description"),
    },
    {
      count: "02",
      countPara: "",
      heading: translate("saas-solution-process-02-header"),
      description: translate("saas-solution-process-02-description"),
    },
    {
      count: "03",
      countPara: "",
      heading: translate("saas-solution-process-03-header"),
      description: translate("saas-solution-process-03-description"),
    },
    {
      count: "04",
      countPara: "",
      heading: translate("saas-solution-process-04-header"),
      description: translate("saas-solution-process-04-description"),
    },
    {
      count: "05",
      countPara: "",
      heading: translate("saas-solution-process-05-header"),
      description: translate("saas-solution-process-05-description"),
    },
    {
      count: "06",
      countPara: "",
      heading: translate("saas-solution-process-06-header"),
      description: translate("saas-solution-process-06-description"),
    },
    {
      count: "07",
      countPara: "",
      heading: translate("saas-solution-process-07-header"),
      description: translate("saas-solution-process-07-description"),
    },
    {
      count: "08",
      countPara: "",
      heading: translate("saas-solution-process-08-header"),
      description: translate("saas-solution-process-08-description"),
    },
  ],
};

export const ourApproachData = [
  {
    count: "01",
    heading: translate("saas-review-header-01"),
    description: translate("saas-review-description-01"),
  },
  {
    count: "02",
    heading: translate("saas-review-header-02"),
    description: translate("saas-review-description-02"),
  },
  {
    count: "03",
    heading: translate("saas-review-header-03"),
    description: translate("saas-review-description-03"),
  },
];
