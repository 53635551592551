// extracted by mini-css-extract-plugin
export var backgroundCircles = "CaseStudiesMain-module--backgroundCircles--PN-v0";
export var card1 = "CaseStudiesMain-module--card1--ImsTh";
export var card2 = "CaseStudiesMain-module--card2--et9sW";
export var card3 = "CaseStudiesMain-module--card3--YmD3J";
export var caseStudiesButtonsContainer = "CaseStudiesMain-module--caseStudiesButtonsContainer--b-qT+";
export var caseStudiesDescription = "CaseStudiesMain-module--caseStudiesDescription--m7JIo";
export var caseStudiesHeader = "CaseStudiesMain-module--caseStudiesHeader--fskGX";
export var caseStudiesMainContainer = "CaseStudiesMain-module--caseStudiesMainContainer--TPmst";
export var caseStudiesSliderContainer = "CaseStudiesMain-module--caseStudiesSliderContainer--XGrc6";
export var caseStudiesTitleCircle = "CaseStudiesMain-module--caseStudiesTitleCircle--q2Nr2";
export var heroBackgroundCircleLeft = "CaseStudiesMain-module--heroBackgroundCircleLeft--wCR3l";
export var sliderHrClassName = "CaseStudiesMain-module--sliderHrClassName--+QRji";
export var sliderItem = "CaseStudiesMain-module--sliderItem--m3LG-";