import React, { FC, ReactElement } from "react";
import * as styles from "./TitleForSections.module.scss";

type Props = {
  headerClassName: string;
  headerWidth?: string;
  title: string | ReactElement;
  transformTest?: string;
};

const TitleForSections: FC<Props> = ({
  headerClassName,
  title,
  headerWidth,
  transformTest,
}) => {
  return (
    <div className={`${styles.titleSectionWrapper} ${transformTest}`}>
      <h2 className={headerClassName} style={{ width: headerWidth }}>
        <span>.</span>
        {title}
      </h2>
    </div>
  );
};

export default TitleForSections;
