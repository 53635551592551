import React, { FC } from "react";
import { Slide } from "pure-react-carousel";
import { UseScrollPosition } from "../../../hooks/UseScrollPosition";
import cx from "classnames";
import * as styles from "../CaseStudiesMain.module.scss";
import { Link } from "gatsby";
import yearsAboutt from "../../../../images/yearsAboutt.png";

type Props = {
  sliderContainerClassName: string;
  sliderImage: any;
  sliderIndex: number;
  sliderDescriptionClassName: string;
  sliderHrClassName: string;
  sliderTitle: string;
  sliderDescription: string;
  companyName: string;
  alt: string;
};

const CaseStudiesSlide: FC<Props> = ({
  sliderContainerClassName,
  sliderImage,
  sliderIndex,
  sliderDescriptionClassName,
  sliderTitle,
  sliderDescription,
  companyName,
  sliderHrClassName,
  alt,
}) => {
  const scrollPosition = UseScrollPosition();

  return (
    <div
      style={{
        transform: `translateY(${
          scrollPosition > 0.52
            ? 10 - 0.5 * sliderIndex
            : 40 * sliderIndex * 0.7
        }px)`,
        transition: "ease-out transform 2s",
        height: "100%",
        width: "40%",
      }}
      className={cx(sliderContainerClassName, styles[`card${sliderIndex}`])}
      key={sliderIndex}
    >
      <Link to={`/case-study/${companyName.toLowerCase()}`}>
        <div id={sliderTitle}>
          <Slide
            className={`case-study-carousel`}
            style={{
              height: "625px !important",
              width: "90%",
            }}
            index={sliderIndex}
          >
            <img src={sliderImage.images.fallback.src} alt={alt} />
          </Slide>
          <div className={sliderDescriptionClassName}>
            <div className={sliderHrClassName} />
            <h5>{sliderTitle}</h5>
            <p>{sliderDescription}</p>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default CaseStudiesSlide;
