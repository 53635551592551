import React, { FC, useContext } from "react";
import * as styles from "./CaseStudiesMain.module.scss";
import { CarouselProvider, Slider } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import CaseStudiesSlide from "./Slider/CaseStudiesSlide";
import CaseStudiesSliderButtons from "./Slider/CaseStudiesSliderButtons";
import TitleForSections from "../../Shared/TitleForSection/TitleForSections";
import CircleOutline from "../../Shared/CircleOutlineAndBlur/CircleOutline";
import { UseImageUrl } from "../../hooks/UseImageUrl";
import { UseWindowSize } from "../../hooks/UseWindowSize";
import InnerWrapper from "../../Shared/InnerWrapper/InnerWrapper";
import translate from "../../../i18n/translate";
import { LanguageContext } from "../../../context/languageContext";

type Props = {
  data: any;
};

const CaseStudiesMain: FC<Props> = ({ data }) => {
  const { language } = useContext(LanguageContext);
  const { width } = UseWindowSize();

  return (
    <div className={styles.caseStudiesMainContainer}>
      <InnerWrapper>
        <TitleForSections
          headerClassName={styles.caseStudiesHeader}
          title={translate("home-caseStudies")}
          headerWidth={"20%"}
        />
        <div
          className={"case-studies-carousel carousel__container"}
          style={{ marginTop: "-10px" }}
        >
          <CarouselProvider
            naturalSlideWidth={120}
            naturalSlideHeight={125}
            totalSlides={data.edges.length}
            currentSlide={0}
            visibleSlides={
              width > 2200
                ? 3.5
                : width > 1600
                ? 2.8
                : width > 1350
                ? 2.2
                : width > 1200
                ? 2
                : width > 1000
                ? 2.3
                : width > 800
                ? 1.8
                : width > 770
                ? 1.5
                : 1
            }
            infinite={true}
          >
            <CaseStudiesSliderButtons
              sliderButtonsClassName={styles.caseStudiesButtonsContainer}
            />
            <Slider className={styles.caseStudiesSliderContainer}>
              {data.edges.map(({ node }: any, index: number) => {
                UseImageUrl(node.mainImage, node._rawMainImage);
                return (
                  <CaseStudiesSlide
                    key={index}
                    sliderContainerClassName={styles.sliderItem}
                    sliderHrClassName={styles.sliderHrClassName}
                    sliderIndex={index}
                    companyName={node.title
                      .toString()
                      .toLowerCase()
                      .replace(/\s+/g, "-")
                      .replace(/[^\w-]+/g, "")
                      .replace(/--+/g, "-")
                      .replace(/^-+/, "")
                      .replace(/-+$/, "")}
                    sliderImage={node.mainImage.asset.gatsbyImageData}
                    alt={node?.altMainImage}
                    sliderDescriptionClassName={styles.caseStudiesDescription}
                    sliderTitle={language === "EN" ? node.title : node.titleDE}
                    sliderDescription={
                      language === "EN"
                        ? node?.caseStudyDescription[0]?.children[0]?.text
                        : node?.caseStudyDescriptionDe[0]?.children[0]?.text
                    }
                  />
                );
              })}
            </Slider>
          </CarouselProvider>
        </div>
        <div className={styles.backgroundCircles}>
          <CircleOutline
            isBlurVisible={false}
            initialScale={0.3}
            animateScale={1}
            transitionType={"easeIn"}
            transitionDelay={0.2}
            transitionDuration={1}
            circleStyleClass={styles.caseStudiesTitleCircle}
          />

          <CircleOutline
            isBlurVisible={false}
            initialScale={0.3}
            animateScale={1}
            transitionType={"easeIn"}
            transitionDelay={0.2}
            transitionDuration={1}
            circleStyleClass={styles.heroBackgroundCircleLeft}
          />
        </div>
      </InnerWrapper>
    </div>
  );
};

export default CaseStudiesMain;
